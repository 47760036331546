<template>
  <div class="column pt-0">

    <div><h4 class="has-text-weight-bold is-size-5 mb-4">Главная</h4></div>

    <div v-if="!isLoading" class="columns is-desktop is-multiline is-align-items-stretch">
      <div
        v-for="card in cards"
        :key="card.title"
        class="column is-one-fifth-desktop is-half-tablet"
      >
        <div class="box" style="height: 100%">
          <router-link :to="card.to" class="is-flex is-flex-direction-column is-align-content-space-evenly" style="height: 100%;">
            <p class="text-font-size has-text-left is-uppercase has-text-grey mb-4"> {{ card.title }} </p>
            <div class="is-flex is-justify-content-space-between is-flex-wrap-wrap is-align-items-center mt-auto">
              <h3 class="count-size has-text-weight-bold is-size-4">{{ data[card.field] }}</h3>
              <div>
                <fa-icon
                  class="icon-gen has-text-grey is-size-3"
                  :icon="['fas', card.icon]"
                />
              </div>
            </div>
          </router-link>
        </div>
      </div>

<!--      <div class="column has-background-white list-item-inner">-->
<!--        <router-link to="/company-list">-->
<!--          <p class="text-font-size has-text-left is-uppercase has-text-grey"> Компании </p>-->
<!--          <div class="is-flex is-justify-content-space-between mt-5 is-flex-wrap-wrap is-align-items-center	">-->
<!--            <h3 class="count-size has-text-weight-bold ">{{ data.company_count }}</h3>-->
<!--            <div>-->
<!--              <fa-icon-->
<!--                class="icon-gen has-text-grey is-size-3"-->
<!--                :icon="['fas','calendar']"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
<!--        </router-link>-->
<!--      </div>-->

<!--      <div class="column has-background-white list-item-inner">-->
<!--        <router-link to="/filials-list">-->
<!--          <p class="text-font-size has-text-left is-uppercase has-text-grey"> Филиалы </p>-->
<!--          <div class="is-flex is-justify-content-space-between mt-5 is-flex-wrap-wrap is-align-items-center	">-->
<!--            <h3 class="count-size has-text-weight-bold ">{{ data.branch_count }}</h3>-->
<!--            <div>-->
<!--              <fa-icon-->
<!--                class="icon-gen has-text-grey is-size-3"-->
<!--                :icon="['fas','calendar']"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
<!--        </router-link>-->
<!--      </div>-->

<!--      <div class="column  has-background-white list-item-inner">-->
<!--        <router-link to="/factory-list">-->
<!--          <p class="text-font-size has-text-left is-uppercase has-text-grey"> заводы-->
<!--            <span class="info-text">/ фабрики / цеха </span>-->
<!--          </p>-->
<!--          <div class="is-flex is-justify-content-space-between mt-5 is-flex-wrap-wrap is-align-items-center	">-->
<!--            <h3 class="count-size has-text-weight-bold ">{{ data.factory_count }}</h3>-->
<!--            <div>-->
<!--              <fa-icon-->
<!--                class="icon-gen has-text-grey is-size-3"-->
<!--                :icon="['fas','industry']"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
<!--        </router-link>-->
<!--      </div>-->

<!--      <div class="column  has-background-white list-item-inner">-->
<!--        <router-link to="/cms-list">-->
<!--          <p class="text-font-size has-text-left is-uppercase has-text-grey"> Cms </p>-->
<!--          <div class="is-flex is-justify-content-space-between mt-5 is-flex-wrap-wrap is-align-items-center	">-->
<!--            <h3 class="count-size has-text-weight-bold ">{{ data.agent_count }}</h3>-->
<!--            <div>-->
<!--              <fa-icon-->
<!--                class="icon-gen has-text-grey  is-size-3"-->
<!--                :icon="['fas','chart-line']"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
<!--        </router-link>-->
<!--      </div>-->

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cards: [
        {
          title: 'Пользователи',
          icon: 'users',
          field: 'user_count',
          to: '/users-list'
        },
        {
          title: 'Компании',
          icon: 'building',
          field: 'company_count',
          to: '/company-list'
        },
        {
          title: 'Филиалы',
          icon: 'building-user',
          field: 'branch_count',
          to: '/filials-list'
        },
        {
          title: 'Заводы / Фабрики / Цеха',
          icon: 'industry',
          field: 'factory_count',
          to: '/factory-list'
        },
        {
          title: 'CMS',
          icon: 'chart-line',
          field: 'agent_count',
          to: '/cms-list'
        },
      ],
      isLoading: true,
    };
  },
  created() {
    this.getMainInfo();
  },
  methods: {
    async getMainInfo() {
      this.isLoading = true;
      try {
        const { data } = await this.$axios.get(`admin`);
        this.data = data?.data || [];
      } catch (e) {
        throw new Error(e);
      }
      this.isLoading = false
    },
  },
};
</script>

<style scoped>
a {
  color: #007BFF;
}



</style>
